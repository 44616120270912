@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

/* .colstyle {
  --ion-grid-column-padding: 0px;
} */

.selectCss {
  --placeholder-opacity: 1;
}

/* .buttonCss {
  --background: linear-gradient(rgb(252, 248, 248), rgb(253, 249, 249));
} */

.centerCenter {
  display: flex;
  justify-content: center;
}

.ratingwrapper {
  display: flex;
  /* justify-content: center; */
  /* width: 100%; */
  padding-top: 8px;
  flex-direction: column;
}

.outeralign {
  display: flex;
  justify-content: space-between;
}

.uksh-logo {
  /* max-width: 400px; */
  /* padding: 40px; */

  object-fit: contain;
  height: 140px;
  /* width: 400px; */
}

.charite-logo {
  /* width: 70%; */
  /* max-width: 400px; */
  margin: 20px;
  object-fit: contain;
  height: 110px;
}

.boximage {
  object-fit: contain;
  height: 50px;
  margin: 10px;
}

.textBlock {
  justify-content: center;
  padding: 10px 5px 25px 5px;
  text-align: center;
  width: 50%;
}

.custom-button-class {
  --width: 95%;
  --max-height: 100vh;
  --max-width: 95%;
}

.custom-button-class .sc-ion-select-popover {
  white-space: normal;
  padding-bottom: 7px;
}

.custom-button-class .alert-radio-label {
  white-space: initial;
  font-size: 16px;
  font-family: "Open Sans";
  font-weight: 600;
}

.custom-button-class .alert-radio-group {
  max-height: 60vh;
  border-top: none;
  border-bottom: none;
}
.custom-button-class .alert-head {
  padding-top: 0px;
}

.custom-button-class .alert-tappable {
  height: auto;
  contain: none;
}

.custom-button-class .alert-wrapper {
  background: linear-gradient(rgb(252, 248, 248), rgb(253, 249, 249));
}

.cards-class .alert-radio-icon {
  display: none;
}

/* ion-radio {
  --color: transparent;
  --color-checked: transparent;
} */

.errorback {
  background: rgb(222 206 206 / 75%);
}

ion-content {
  --background: transparent;
  --ion-color-base: transparent !important;
}

.textareacss {
  --background: #d7d8da;
}

/* .ion-page {
  background: linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),
    url("../media/nocut.jpg") no-repeat;
} */

.toolheader {
  /* --background: rgba(222, 228, 213, 0.651); */
  --background: linear-gradient(
    rgba(255, 255, 255, 0.623),
    rgba(255, 255, 255, 0.623)
  );
}

.transbutton {
  /* --background: rgba(222, 228, 213, 0.651); */
  --background: linear-gradient(
    rgba(255, 255, 255, 0.623),
    rgba(255, 255, 255, 0.623)
  );
}

.maincontent {
  height: 100vh;
  /* overflow: auto; */
}

.maincontent::-webkit-scrollbar {
  width: 6px;
}

.maincontent::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.maincontent::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.info {
  margin: 45px;
  color: #808080;
  text-align: center;
}
.infodaten {
  font-weight: bold;
  color: #50b148;
  margin: 20px;
}
